@import "abstracts/_variables.scss";

@import "../widgets/Camera/index.scss";

@import "../pages/VisitorRegistrationForm/visitorRegistrationForm.scss";

@import "../pages/VisitorRequestPage/visitorRequest.scss";

@import "../pages/SecurityLogin/SecurityLogin.scss";

@import "../pages/ViewHostResponse/viewHostResponse.scss";

html,
body,
#root {
  min-height: 100vh;
  font-family: "Poppins", sans-serif !important;
  background: var(--color-grey);
}

.d-flex {
  display: flex;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.cursor-pointer {
  cursor: pointer !important;
}