.login-form {
    background-color: f4f4f4;
    background-size: cover;
    background-repeat: no-repeat;
}
.nsp-logo{
    background-image: url("../../assets/nsp-logo.png");
    height: 12vh;
    background-repeat: no-repeat;
}
.modal-logo{
    background-image: url("../../assets/nsp-logo.png");
    background-repeat: no-repeat;
}


.form-control placeholder{
    opacity: 50;
    align-items: center;
 }

 .Purpose-Of-Visit{
    height: 75px;
 }